import React from "react";
import { Fragment } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import ProjectCarouselComponent from "../../../generalComponents/ProjectCarouselComponent";
import "./styles.css";

const GalleryComponent = ({ serviceId }) => {
  return (
    <Fragment>
      <div className="gallery-container">
        <ProjectCarouselComponent
          gallery={serviceId.galeria}
        ></ProjectCarouselComponent>
      </div>
    </Fragment>
  );
};

export default GalleryComponent;
