import DJI_0519 from './img/projects/JuanNLopez/DJI_0519.jpg';
import DJI_0524 from './img/servicios/Terracerias/DJI_0549.jpg';
import DJI_0525 from './img/servicios/ObraCivil/Xb1X8OnQ.jpeg';
import DJI_0526 from './img/projects/HDI/z1.jpg';
// import DJI_0529 from './img/projects/JuanNLopez/DJI_0529.jpg';
// import DJI_0530 from './img/projects/JuanNLopez/DJI_0530.jpg';
// import DJI_0541 from './img/projects/JuanNLopez/DJI_0541.jpg';

export const Projects = [
    {
        "id": "1",
        "nombre": "ESTADIO JUAN N. LÓPEZ / PATRONATO NUEVO ESTADIO JUAN N. LÓPEZ (1994)",
        "imagen": DJI_0519,
        "text": "Construcción de estadio para futbol con capacidad para 15 mil aficionados y 102 palcos.",
        "datos": [
            {
                "dato": "Construcción en 7 meses."
            },
            {
                "dato": "Mejoramiento adecuaciones del terreno, ya que anteriormente ahí se encontraba el basurero municipal."
            },
            {
                "dato": "Casa de los Reboceros de La Piedad desde 1994."
            }
        ],
        "gallery": [
            {
                "id": "1",
                "img": "/servicios/ObraCivil/DJI_0519.jpg"
            },
            {
                "id": "2",
                "img": "/servicios/ObraCivil/DJI_0529.jpg"
            },
            {
                "id": "3",
                "img": "/servicios/ObraCivil/DJI_0525.jpg"
            }
        ],
        "next": "2",
        "nextN": "FRACCIONAMIENTO LAS MARGARITAS I Y II"
    },
    {
        "id": "2",
        "nombre": "FRACCIONAMIENTO LAS MARGARITAS I Y II (2003 – 2012)",
        "imagen": DJI_0524,
        "text": "Urbanización y construcción de infraestructura de los fraccionamientos residenciales mas importantes de la ciudad.",
        "datos": [
            {
                "dato": "Elaboración del proyecto de lotificación y urbanización."
            },
            {
                "dato": "Diseño Arquitectónico de accesos y áreas comunes."
            },
            {
                "dato": "Mas de 20mil m2 de construcción de pavimentos de adocreto."
            },
            {
                "dato": "Construcción de Casetas de control de acceso."
            },
            {
                "dato": "Construcción de casas club y ares de esparcimiento."
            }
        ],
        "gallery": [
            {
                "id": "1",
                "img": "/servicios/Terracerias/DJI_0549.jpg"
            },
            {
                "id": "2",
                "img": "/servicios/Terracerias/DJI_0537.jpg"
            },
            {
                "id": "3",
                "img": "/servicios/Terracerias/DJI_0534.jpg"
            }
        ],
        "next": "3",
        "nextN": "TURBOMAQUINAS / NAVE INDUSTRIAL 10,000 M2 (2012)"
    },
    {
        "id": "3",
        "nombre": "TURBOMAQUINAS / NAVE INDUSTRIAL 10,000 M2 (2012)",
        "imagen": DJI_0525,
        "text": "Construcción de nave industrial de 10,000 m2 en La Piedad, Michoacán.",
        "datos": [
            {
                "dato": "Construcción en 13 meses."
            },
            {
                "dato": "Cimentación especial de nave debido al alto nivel de aguas freáticas."
            },
            {
                "dato": "Construcción de dos edificios de oficinas en el interior de 600m2 de construcción cada uno."
            },
            {
                "dato": "Cimentaciones especiales para tornos y maquinaria."
            },
            {
                "dato": "Construcción de túnel de 25 metros de largo, para intercomunicación de instalaciones"
            }
        ],
        "gallery": [
            {
                "id": "1",
                "img": "/servicios/ObraCivil/Xb1X8OnQ.jpeg"
            },
            {
                "id": "2",
                "img": "/servicios/ObraCivil/zkA3fIlQ.jpeg"
            },
            {
                "id": "3",
                "img": "/servicios/ObraCivil/ojqP7Eqg.jpeg"
            },
            {
                "id": "4",
                "img": "/servicios/ObraCivil/BcU4nLiA.jpeg"
            },
            {
                "id": "5",
                "img": "/servicios/ObraCivil/oeViU9Jg.jpeg"
            }
        ],
        "next": "4",
        "nextN": "HOTEL HOLLIDAY INN LA PIEDAD"
    },
    {
        "id": "4",
        "nombre": "HOTEL HOLLIDAY INN LA PIEDAD (1993)",
        "imagen": DJI_0526,
        "text": "Hotel de la cadena Holliday Inn construido en el año de 1993, se llevó acabo la ejecución total del proyecto, desde la construcción hasta el equipamiento propio del hotel.",
        "datos": [
            {
                "dato": "Único hotel 5 estrellas en La Piedad."
            },
            {
                "dato": "+ de 100 habitaciones."
            },
            {
                "dato": "Construcción total en 9 meses ."
            }
        ],
        "gallery": [
            {
                "id": "1",
                "img": "/projects/HDI/z1.jpg"
            },
            {
                "id": "2",
                "img": "/projects/HDI/z2.jpg"
            },
            {
                "id": "3",
                "img": "/projects/HDI/z3.jpg"
            }
        ],
        "next": "1",
        "nextN": "ESTADIO JUAN N. LÓPEZ"
    }
]