import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "./styles.css";

const NavComponent = () => {

  const navColor = () => {
    $(function () {
      $(window).scroll(function () {

        if ($(this).scrollTop() > 100) {
          document.getElementById("nav").classList = "navbar navbar-expand-lg navbar-light bg-gray fixed-top";
        } else {
          document.getElementById("nav").classList = "navbar navbar-expand-lg navbar-light bg-light fixed-top";
        }
      });
    });
  }

  navColor()
    ;
  return (
    <Fragment>
      <Navbar id="nav" bg="light" expand="lg" fixed="top">
        <Navbar.Brand>
          <Link to="/">
            <img className="img-nav" src="../../../assets/img/nav/image.png" alt="" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link className="nav-link" to="/">Inicio</Link>
            <Link className="nav-link" to="/projects">Proyectos</Link>
            <Link className="nav-link" to="/contact">Contacto</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

export default NavComponent;
