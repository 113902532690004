import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SubNavBarComponent from "../../../othersComponents/subNavBar";
import { Link } from "react-router-dom";
import $ from "jquery";
import "./styles.css";

const HomeComponent = () => {
  useEffect(() => {
    $("#corousel div.home-contenedor:last").insertBefore(
      "#corousel div.home-contenedor:first"
    );
    let posicionInicial;
    posicionInicial = 100;
    $("#corousel").css("margin-left", "-" + posicionInicial + "%");
  }, []);

  function sliderNextEvent() {
    let desplazar;
    let posicionOriginal;

    desplazar = 200;
    posicionOriginal = 100;

    $("#corousel").animate(
      { marginLeft: "-" + desplazar + "%" },
      700,
      function () {
        $("#corousel div.home-contenedor:first").insertAfter(
          "#corousel div.home-contenedor:last"
        );
        $("#corousel").css("margin-left", "-" + posicionOriginal + "%");
      }
    );
  }

  function sliderPreviewEvent() {
    let desplazar;
    let posicionOriginal;
    desplazar = 0;
    posicionOriginal = 100;
    $("#corousel").animate({ marginLeft: desplazar + "%" }, 700, function () {
      $("#corousel div.home-contenedor:last").insertBefore(
        "#corousel div.home-contenedor:first"
      );
      $("#corousel").css("margin-left", "-" + posicionOriginal + "%");
    });
  }

  return (
    <Fragment>
      <div className="home-container">
        <div className="overlay">
          <Container>
            <Row>
              <Col>
                <div className="text-title-h1 color-text-white">
                  <div>especialistas</div>
                  <div> en construcción</div>
                </div>
                <Link to="/projects">
                  <button className="btn-medium btn-background-blue">
                    Ver proyectos
                  </button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="carousel-home-contenedor">
          <div className="home-arrow-1" onClick={() => sliderPreviewEvent()}>
            <img src="../../../../assets/img/home/Anterior.svg" alt="" srcSet="" />
          </div>
          <div className="carousel-home">
            <div id="corousel" className="home-carousel">
              <div className="home-contenedor">
                <div className="home-card-doc">
                  <div className="home-card-box">
                    <img src="../../../../assets/img/home/DJI_0500.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="home-contenedor">
                <div className="home-card-doc">
                  <div className="home-card-box">
                    <img src="../../../../assets/img/home/DJI_0530.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="home-contenedor">
                <div className="home-card-doc">
                  <div className="home-card-box">
                    <img src="../../../../assets/img/home/DJI_0541.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-arrow-2" onClick={() => sliderNextEvent()}>
            <img src="../../../../assets/img/home/Siguiente.svg" alt="" srcSet="" />
          </div>
        </div>
        <SubNavBarComponent></SubNavBarComponent>

      </div>
    </Fragment>
  );
};

export default HomeComponent;
