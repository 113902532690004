import React from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const MisionComponent = () => {
  return (
    <Fragment>
      <div className="mision-container">
        <Container>
          <Row>
            <Col lg={6} align="center">
              <div className="img-c-container">
                <img
                  className="img-c"
                  src="../../../../assets/img/home/escaleras.png"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-grid-left">
                <div className="text-title-h3">NOSOTROS</div>
                <div className="text-title-h2">
                  <div> Más de 35 años</div>
                  <div>de experiencia</div>
                </div>
                <div className="text-p">
                  Iniciamos nuestro camino en el año de 1984, como una empresa
                  dedicada a la construcción. A lo largo de los años y con más
                  de 35 años de experiencia en el ramo, nos hemos especializado
                  en Obra civil, publica e industrial, vivienda de todos los
                  niveles, movimiento de tierras, urbanización, y en años
                  recientes en la Construcción de Invernaderos de alta
                  tecnología. Comprometidos con el desarrollo eficiente y
                  siempre en tiempo de todos y cada uno de nuestros proyectos,
                  buscando siempre la satisfacción de nuestros clientes.
                  Contamos con toda la infraestructura y maquinaria, propia,
                  necesaria para cumplir con todos lo requerimientos que demanda
                  la construcción.
                </div>
                <div className="btn-mision-container">
                  <a
                    href="../../../../assets/curriculum/CURRICULUM 2021.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn-large btn-background-blue">
                      Descargar currículum
                    </button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default MisionComponent;
