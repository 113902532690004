import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ProjectCarouselComponent from "../../../generalComponents/ProjectCarouselComponent";
import "./styles.css";
import { Link } from "react-router-dom";

const ProjectComponent = (props) => {
  return (
    <Fragment>
      <div
        className="project-container"
        style={{
          backgroundImage: "url(" + props.project.imagen + ")",
        }}
      >
        <div className="overlay">
          <div className="proyecto-nombre-container">
            <div className="text-title-h1 color-text-white">
              {props.project.nombre}
            </div>
            <div className="text-title-h3 color-text-white">Obra pública</div>
          </div>
          <div className="siguiente-proyecto-container">
            <div>
              <div className="siguiente-proyecto text-title-h3 color-text-white">
                <Link to={"/project/" + props.project.next}>
                  {" "}
                  Siguiente proyecto
                </Link>
              </div>
              <div className="siguiente-proyecto-nombre text-p-small color-text-white">
                <Link to={"/project/" + props.project.next}>
                  {props.project.nextN}
                </Link>
              </div>
            </div>
            <div className="btn-next-proyect-container">
              <Link id="btn-next" to={"/project/" + props.project.next}>
                &#8250;
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="project-info-container">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="display-grid-left">
                {/* <div className="capacidad-palcos">
                  <div className="capacidad-container">
                    <div className="text-p-small text-p-weight">
                      <span> — </span> capacidad
                    </div>
                    <div className="text-title-h2">15,000</div>
                  </div>
                  <div className="palcos-container">
                    <div className="text-p-small text-p-weight">
                      <span> — </span> palcos
                    </div>
                    <div className="text-title-h2">102</div>
                  </div>
                </div> */}
                <div className="customer-date-container">
                  {/* <div className="construction-date">
                    <div className="text-p-small text-p-weight">
                      <span> — </span> Construido en
                    </div>
                    <div className="text-p">2007</div>
                  </div> */}
                  <div className="customer">
                    {/* <div className="text-p-small text-p-weight">
                      <span> — </span>cliente
                    </div> */}
                    <div className="text-p">
                      {props.project.text}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-grid-left">
                <div className="text-p-small text-p-weight">
                  <span> — </span> ¿Qué hicimos?
                </div>
                {
                  props.project.datos ? (
                    props.project.datos.map((index, i) => {
                      return (
                        <div className="info-text-flex">
                          <div className="text-title-h2">0{i + 1}</div>
                          <div className="text-p text-p-p-l">
                            {props.project.datos[i].dato}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Fragment></Fragment>
                  )
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ProjectCarouselComponent gallery={props.project.gallery}></ProjectCarouselComponent>
    </Fragment>
  );
};

ProjectComponent.propTypes = {
  project: PropTypes.object,
};

export default ProjectComponent;
